/* eslint-disable no-console */
// lib/firebase.ts
// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyD5-R3Svp7C_ASSNMxNjxm269EJdbsVvwY',
    authDomain: 'pelagic-gist-420715.firebaseapp.com',
    projectId: 'pelagic-gist-420715',
    storageBucket: 'pelagic-gist-420715.appspot.com',
    messagingSenderId: '1036706285027',
    appId: '1:1036706285027:web:9f7908de4ad54427d28cd6',
};

// Initialize Firebase

const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
